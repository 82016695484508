.App {
  text-align: center;
}

.purple-bg{
  background-color: rgb(45, 32, 69);
}
.light-purple-bg{
  background-color: #b08ae4;
}
.text-purple{
  color:rgb(45, 32, 69);
}
