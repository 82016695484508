.footer-bg {
    font-size: 0.8rem;
    line-height: 1.5;
    background-color: rgb(45, 32, 69);
    margin-top: 40px;
}

.footer-designed-by{
    color: #c36;
    background-color: transparent;
    text-align: center;
    font-family: "Rosarivo", sans-serif;
    font-weight: 400;
}