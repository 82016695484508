.carousel .thumb img {
    width: 100% !important;
    height: 100% !important;
}

.carousel .slide img {
    max-height: 300px;  /* change this to whatever you want */
    width: auto;
}
.carousel .slide .legend{
    width: auto;
    margin-left: auto;
    margin-right: auto;
}

.carousel-bg{
    background-repeat: no-repeat;
    background-size: cover;
    background: linear-gradient(to right, white, rgb(45, 32, 69));
}

.screen-overlay{
    width: 0%;
    height: 100%;
    z-index: 30;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s linear, visibility .1s, width 1s ease-in;
}

.header-image{
    background: linear-gradient(rgba(48, 39, 66, 0.863) 0%, rgba(38, 27, 58, 0.718) 100%), url("https://res.cloudinary.com/noi/image/upload/v1628058167/meditex/slide-06.jpg") no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
}

@media (min-width: 700px) {
    .header-image{
        height: 50vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
