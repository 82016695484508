.read-more-link{
    padding: 0 0px;
    border-radius: 2px;
    white-space: pre-wrap;
    font-family: "Source Code Pro", monospace;
    font-weight: 500;
    word-break: break-word;
    color: #006395;
    background: rgba(176,176,176,0.25);
    border: 1px solid rgba(0,0,0,0.15);
}

.extra-content{
}
.service-card {
   border: 1px solid #ccc;
   background-color: #e5e5e5;
}
