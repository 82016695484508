.contact-bg {
    background-color: #e5e5e5;
    height: 920px;
}

.text-light-blue{
    color: #12232E;
}

@media screen and (min-width: 640px){
    .contact-bg{
        height: 450px;
    }
}
